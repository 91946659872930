var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Data ")]),_vm._v(" "),_c('small',[_vm._v("Tables")]),_c('div',{staticClass:"card-header-actions"},[_c('a',{staticClass:"card-header-action",attrs:{"href":"https://coreui.io/vue/docs/components/form-components","rel":"noreferrer noopener","target":"_blank"}})])]),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"column-filter":"","table-filter":"","items-per-page-select":"","items-per-page":5,"hover":"","sorter":"","pagination":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"show_details",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleDetails(item, index)}}},[_vm._v(" "+_vm._s(Boolean(item._toggled) ? 'Hide' : 'Show')+" ")])],1)]}},{key:"details",fn:function(ref){
var item = ref.item;
return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[_c('CCardBody',[_c('CMedia',{attrs:{"aside-image-props":{ height: 102 }}},[_c('h4',[_vm._v(" "+_vm._s(item.username)+" ")]),_c('p',{staticClass:"text-muted"},[_vm._v("User since: "+_vm._s(item.registered))]),_c('CButton',{attrs:{"size":"sm","color":"info"}},[_vm._v(" User Settings ")]),_c('CButton',{staticClass:"ml-1",attrs:{"size":"sm","color":"danger"}},[_vm._v(" Delete ")])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }