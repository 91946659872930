//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CTableWrapper from './Table.vue'
import usersData from '../admin/UsersData'

const items = [
	{ username: 'Samppa Nori', registered: '2012/01/01', role: 'Member', status: 'Active'},
	{ username: 'Estavan Lykos', registered: '2012/02/01', role: 'Staff', status: 'Banned'},
	{ username: 'Chetan Mohamed', registered: '2012/02/01', role: 'Admin', status: 'Inactive'},
	{ username: 'Derick Maximinus', registered: '2012/03/01', role: 'Member', status: 'Pending'},
	{ username: 'Friderik Dávid', registered: '2012/01/21', role: 'Staff', status: 'Active'},
	{ username: 'Yiorgos Avraamu', registered: '2012/01/01', role: 'Member', status: 'Active'},
	{ username: 'Avram Tarasios', registered: '2012/02/01', role: 'Staff', status: 'Banned', _classes: 'table-success'},
	{ username: 'Quintin Ed', registered: '2012/02/01', role: 'Admin', status: 'Inactive'},
	{ username: 'Enéas Kwadwo', registered: '2012/03/01', role: 'Member', status: 'Pending'},
	{ username: 'Agapetus Tadeáš', registered: '2012/01/21', role: 'Staff', status: 'Active'},
	{ username: 'Carwyn Fachtna', registered: '2012/01/01', role: 'Member', status: 'Active', _classes: 'table-info'},
	{ username: 'Nehemiah Tatius', registered: '2012/02/01', role: 'Staff', status: 'Banned'},
	{ username: 'Ebbe Gemariah', registered: '2012/02/01', role: 'Admin', status: 'Inactive'},
	{ username: 'Eustorgios Amulius', registered: '2012/03/01', role: 'Member', status: 'Pending'},
	{ username: 'Leopold Gáspár', registered: '2012/01/21', role: 'Staff', status: 'Active'},
	{ username: 'Pompeius René', registered: '2012/01/01', role: 'Member', status: 'Active'},
	{ username: 'Paĉjo Jadon', registered: '2012/02/01', role: 'Staff', status: 'Banned'},
	{ username: 'Micheal Mercurius', registered: '2012/02/01', role: 'Admin', status: 'Inactive'},
	{ username: 'Ganesha Dubhghall', registered: '2012/03/01', role: 'Member', status: 'Pending'},
	{ username: 'Hiroto Šimun', registered: '2012/01/21', role: 'Staff', status: 'Active'},
	{ username: 'Vishnu Serghei', registered: '2012/01/01', role: 'Member', status: 'Active'},
	{ username: 'Zbyněk Phoibos', registered: '2012/02/01', role: 'Staff', status: 'Banned'},
	{ username: 'Einar Randall', registered: '2012/02/01', role: 'Admin', status: 'Inactive', _classes: 'table-danger'},
	{ username: 'Félix Troels', registered: '2012/03/21', role: 'Staff', status: 'Active'},
	{ username: 'Aulus Agmundr', registered: '2012/01/01', role: 'Member', status: 'Pending'}
]

const fields = [
	{ key: 'username', _style:'min-width:200px' },
	'registered',
	{ key: 'role', _style:'min-width:100px;' },
	{ key: 'status', _style:'min-width:100px;' },
	{
		key: 'show_details',
		label: '',
		_style: 'width:1%',
		sorter: false,
		filter: false
	}
]

export default {
  name: 'DataTables',
  components: { CTableWrapper },
	data: function () {
		return {
			show: true,
			isCollapsed: true,
			loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
			items: items.map((item, id) => { return {...item, id}}),
			fields,
			details: [],
			collapseDuration: 0
		}
	},
  methods: {
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        let temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    },

    getShuffledUsersData () {
      return this.shuffleArray(usersData.slice(0))
    },

		getBadge (status) {
			switch (status) {
				case 'Active': return 'success'
				case 'Inactive': return 'secondary'
				case 'Pending': return 'warning'
				case 'Banned': return 'danger'
				default: 'primary'
			}
		},

		toggleDetails (item) {
			this.$set(this.items[item.id], '_toggled', !item._toggled)
			this.collapseDuration = 300
			this.$nextTick(() => { this.collapseDuration = 0})
		}
  }
}
